body,
html {
  height: 100dvh;
  height: -webkit-fill-available;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.main {
  width: 100%;
}

.vx-description-image {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 600px) {
  .main {
    height: calc(100dvh - 48px);
  }
}

hr {
  border: 1px solid var(--mui-palette-layout-border-color);
}


@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}